import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";
import { PropsWithChildren } from "react";


export default function DeleteDialog({
  isOpen,
  closeDialog,
  onDelete,
}: PropsWithChildren<{
  isOpen: boolean;
  closeDialog: () => void;
  onDelete: () => void;
}>) {
  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>Delete Grade</DialogTitle>
      <DialogContent><Typography variant="body1">Are you sure you want to delete?</Typography></DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeDialog}>
          Cancel
        </Button>
        <Button onClick={onDelete} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
