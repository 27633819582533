import { Add, Delete, SchoolOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  CollectionReference,
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
} from "firebase/firestore";
import { PropsWithChildren, useState } from "react";
import { useFirestoreCollectionData } from "reactfire";
import AddGrade from "../add_grade/add_grade";
import DeleteDialog from "../delete_dialog/delete_dialog";
import Subjects from "../subjects/subjects";

export default function Contents({
  collectionRef,
}: PropsWithChildren<{ collectionRef: CollectionReference }>) {
  const [selectedGrade, setSelectedGrade] = useState<String | null>(null);
  const { data } = useFirestoreCollectionData(
    query(collectionRef, orderBy("order", "asc")),
    {
      idField: "id",
    }
  );

  const [isGradeAddOpen, setIsGradeAddOpen] = useState<boolean>(false);
  function addGrade(name: string, icon: string) {
    addDoc(collectionRef, {
      name,
      icon,
      order: new Date().valueOf(),
    });
  }

  const [isDeleteGradeOpen, setIsDeleteGradeOpen] = useState<boolean>(false);
  function deleteGrade() {
    deleteDoc(doc(collectionRef.firestore,collectionRef.path,selectedGrade as string));
    setIsDeleteGradeOpen(false);
  }

  const selectedGradeDetails = data?.find((grade) => grade.id === selectedGrade);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h6">Grades</Typography>
      </Box>

      <Card>
        {/* <CardContent> */}
        <Grid container justifyContent="stretch">
          <Grid item md={3} overflow={"scroll"}>
            <Box display="flex" alignItems="stretch" height="100%">
              <Box flex={1}>
                <Box display="flex" p={2}>
                  <Button
                    size="large"
                    startIcon={<Add />}
                    onClick={() => setIsGradeAddOpen(true)}
                  >
                    Add
                  </Button>
                </Box>
                <List>
                  <Divider />
                  {data?.map((grade) => (
                    <ListItemButton
                      sx={{
                        p: 2,
                        bgcolor: grade.id === selectedGrade ? grey[200] : null,
                      }}
                      key={grade.id}
                      onClick={() => setSelectedGrade(grade.id)}
                    >
                      <ListItemIcon>
                        <SchoolOutlined />
                      </ListItemIcon>
                      <ListItemText primary={grade.name}></ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Box>
              <Box>
                <Divider orientation="vertical" />
              </Box>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box display={"flex"} flexDirection={"column"}>
              <Box p={2} mt={1} display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="h6">{selectedGradeDetails?.name}</Typography>
                </Box>
                <Box>
                  {selectedGrade && <IconButton onClick={() => setIsDeleteGradeOpen(true)}>
                    <Delete />
                  </IconButton>}
                </Box>
              </Box>
              {selectedGrade != null ? (
                <Subjects
                  collectionRef={collection(
                    collectionRef.firestore,
                    collectionRef.path,
                    selectedGrade as string,
                    "subjects"
                  )}
                />
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Card>

      <AddGrade
        isOpen={isGradeAddOpen}
        closeDialog={() => setIsGradeAddOpen(false)}
        addGrade={addGrade}
      />

      <DeleteDialog
        isOpen={isDeleteGradeOpen}
        closeDialog={() => setIsDeleteGradeOpen(false)}
        onDelete={deleteGrade}
      />
    </Box>
  );
}
