import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled
} from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { useFeedback } from "../../../../providers/feedback/feeedback";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddSubject({
  isOpen,
  closeDialog,
  addSubject,
}: PropsWithChildren<{
  isOpen: boolean;
  closeDialog: () => void;
  addSubject: (name: string, icon: string) => void;
}>) {
  const [subjectName, setsubjectName] = useState("");
  const [subjectDescription, setSubjectDescription] = useState<string>("");
  const { showSnackbar } = useFeedback();

  function clear() {
    setsubjectName("");
    setSubjectDescription("");
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clear();
        closeDialog();
      }}
    >
      <DialogTitle>Add Subject</DialogTitle>
      <DialogContent>
        <br />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Name"
              sx={{ minWidth: 300 }}
              value={subjectName}
              onChange={({ target: { value } }) => setsubjectName(value)}
            ></TextField>
          </Grid>
          <Grid item md={12}>
          <TextField
              fullWidth
              variant="standard"
              placeholder="Description"
              sx={{ minWidth: 300 }}
              value={subjectDescription}
              onChange={({ target: { value } }) => setSubjectDescription(value)}
              multiline
              maxRows={5}
              minRows={3}
            ></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            clear();
            closeDialog();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (subjectName.length <= 0) {
              showSnackbar("Enter subject");
            } else if (!subjectDescription) {
              showSnackbar("Enter description");
            } else {
              closeDialog();
              addSubject(subjectName, subjectDescription);
            }
          }}
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
