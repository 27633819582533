import { Delete, Edit, NoteOutlined } from "@mui/icons-material";
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { CollectionReference, orderBy, query } from "firebase/firestore";
import { useFirestoreCollectionData } from "reactfire";

export default function Chapters({
  description,
  collectionRef,
}: {
  description: string|null,
  collectionRef: CollectionReference;
}) {
  const { data } = useFirestoreCollectionData(
    query(collectionRef, orderBy("order", "asc")),
    {
      idField: "id",
    }
  );
  return (
    <Box height={600} display='flex' flexDirection={'column'}>
      <Box p={3}>
        <Typography variant="body1">Description</Typography>
        <Typography pt={1} variant="body2">{description}</Typography>
      </Box>
      <Box px={3} py={1}>
        <Typography variant="body1">Chapters</Typography>
      </Box>
      <Box flex={1}  overflow={'scroll'}>
        <List>
          {data?.map((chapter) => (
            <ListItem sx={{ px: 3 }} key={chapter.id}>
              <ListItemIcon>
                <NoteOutlined />
              </ListItemIcon>
              <ListItemText primary={chapter.name}></ListItemText>
              <IconButton>
                <Edit />
              </IconButton>
              <Box m={1}></Box>
              <IconButton>
                <Delete />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
