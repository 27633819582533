import { Add, AutoStoriesOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { CollectionReference, addDoc, collection, orderBy, query } from "firebase/firestore";
import { useState } from "react";
import { useFirestoreCollectionData } from "reactfire";
import AddSubject from "../add_subject/add_subject";
import Chapters from "../chapters/chapters";

export default function Subjects({
  collectionRef,
}: {
  collectionRef: CollectionReference;
}) {
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [selectedSubjectDescription, setSelectedSubjectDescription] = useState<string | null>(null);
  const { data } = useFirestoreCollectionData(query(collectionRef, orderBy("order", "asc")), {
    idField: "id",
  });

  const [isSubjectAddOpen, setIsSubjectAddOpen] = useState<boolean>(false);
  function addSubject(name: string, description: string) {
    addDoc(collectionRef, {
      name,
      description,
      order: new Date().valueOf(),
    });
  }


  return (
    <Box flex={1} height={'100%'}>
      <Typography variant="body1" mb={2} ml={2}>
        Subjects
      </Typography>
      <Divider />
      <Box>
        <Grid container>
          <Grid item md={4}>
            <Box display="flex" alignItems="stretch" height='100%'>
              <Box flex={1} height="100%" overflow={'scroll'}>
                <Box display="flex" pt={2} px={1} pb={0}>
                  <Button size="large" startIcon={<Add />} onClick={() => setIsSubjectAddOpen(true)}>
                    Add
                  </Button>
                </Box>
                <List>
                  {data?.map((subject) => (
                    <ListItemButton
                      sx={{
                        p: 2,
                        bgcolor:
                          subject.id === selectedSubject ? grey[200] : null,
                      }}
                      key={subject.id}
                      onClick={() => {setSelectedSubject(subject.id);
                        setSelectedSubjectDescription(subject.description)}}
                    >
                      <ListItemIcon>
                        <AutoStoriesOutlined />
                      </ListItemIcon>
                      <ListItemText primary={subject.name}></ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Box>
              <Box>
                <Divider orientation="vertical" />
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} height={'100%'} overflow={'scroll'}>
            {selectedSubject != null && (
              <Chapters
              description={selectedSubjectDescription}
                collectionRef={collection(
                  collectionRef.firestore,
                  collectionRef.path,
                  selectedSubject as string,
                  "chapters"
                )}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <AddSubject
      isOpen={isSubjectAddOpen}
closeDialog={() => setIsSubjectAddOpen(false)}
      addSubject={addSubject} />
    </Box>
  );
}
