import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import MainLayout from "../../layouts/main/main";
import Contents from "./components/contents/contents";
import { collection } from "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";

export default function Learn() {
  const [tab, setTab] = useState<"Dyslexia" | "ADHD">("Dyslexia");
  const firestore = useFirestore();

  function getCollection() {
    switch (tab) {
      case "Dyslexia":
        return collection(firestore, "dyslexia");
      case "ADHD":
        return collection(firestore, "adhd");
    }
  }

  return (
    <MainLayout>
      <Tabs
        value={tab}
        onChange={(e, value) => setTab(value)}
        aria-label="basic tabs example"
      >
        <Tab label="Dyslexia" value="Dyslexia" />
        <Tab label="ADHD" value="ADHD" />
      </Tabs>
      <Box mb={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item md={10}>
          <Contents collectionRef={getCollection()} />
        </Grid>
      </Grid>
    </MainLayout>
  );
}
