import { createBrowserRouter } from "react-router-dom";
import useUser from "./providers/user_provider/user_provider";
import AccessDenied from "./views/access_denied/access_denied";
import Dashboard from "./views/dashboard/dashboard";
import Learn from "./views/learn/learn";
import Users from "./views/users/users";

interface PrivateRouteProps {
  element: React.ReactNode;
  // You can include other props as needed
}
const PrivateRoute: any = ({ element }: PrivateRouteProps) => {
  const { isActiveUser } = useUser();
  return isActiveUser ? element : <AccessDenied />;
};

export const routes = [
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/learn",
    element: <Learn />,
  },
  {
    path: "/users",
    element: <Users />,
  },
];

export default createBrowserRouter(routes);
