import { ThemeProvider } from "@mui/material";
import theme from "@repo/ui/theme";
import React from "react";
import ReactDOM from "react-dom/client";
import { FirebaseAppProvider } from "reactfire";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource-variable/plus-jakarta-sans";


const firebaseConfig = {
  apiKey: "AIzaSyBIA5JxcMKt8aFxjLkjwtk1m9LXgslT5qQ",
  authDomain: "bestread-dev-4214c.firebaseapp.com",
  projectId: "bestread-dev-4214c",
  storageBucket: "bestread-dev-4214c.appspot.com",
  messagingSenderId: "132759450061",
  appId: "1:132759450061:web:f11e9958fb0f78a1b41484",
  measurementId: "G-XSG70PZ6JT",
};


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </FirebaseAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
