import {
  CloudUploadOutlined
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled
} from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { useFeedback } from "../../../../providers/feedback/feeedback";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddGrade({
  isOpen,
  closeDialog,
  addGrade,
}: PropsWithChildren<{
  isOpen: boolean;
  closeDialog: () => void;
  addGrade: (name: string, icon: string) => void;
}>) {
  const [gradeName, setGradeName] = useState("");
  const [icon, setIcon] = useState<string | null>(null);
  const [iconAsURL, setIconAsURL] = useState<string | null>(null);
  const { showSnackbar } = useFeedback();

  function clear() {
    setGradeName("");
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clear();
        closeDialog();
      }}
    >
      <DialogTitle>Add Grade</DialogTitle>
      <DialogContent>
        <br />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Grade Name"
              sx={{ minWidth: 300 }}
              value={gradeName}
              onChange={({ target: { value } }) => setGradeName(value)}
            ></TextField>
          </Grid>
          <Grid item md={12}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadOutlined />}
            >
              Upload icon file
              <VisuallyHiddenInput
                type="file"
                accept=".svg"
                onChange={(e) => {
                  if (e.target.files && e.target.files?.length > 0) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      if (e?.target?.result) {
                        console.log(e?.target?.result);
                        setIcon(e.target.result as string);
                      }
                    };
                    reader.readAsText(e.target.files[0]);

                    const urlReader = new FileReader();
                    urlReader.onload = (e) => {
                      if (e?.target?.result) {
                        console.log(e?.target?.result);
                        setIconAsURL(e.target.result as string);
                      }
                    };
                    urlReader.readAsDataURL(e.target.files[0]);
                  }
                }}
              />
            </Button>
          </Grid>
          <Grid item md={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={100}
              p={4}
              border={`1px solid #eee`}
              bgcolor={"#eee"}
              borderRadius={1}
            >
              {iconAsURL && <img src={iconAsURL} height={120} width={120} />}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            clear();
            closeDialog();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (gradeName.length <= 0) {
              showSnackbar("Enter grade");
            } else if (!icon) {
              showSnackbar("Select icon");
            } else {
              closeDialog();
              addGrade(gradeName, icon!);
            }
          }}
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
