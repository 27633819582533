import { Menu } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { PropsWithChildren } from "react";

export default function MainLayout({ children }: PropsWithChildren) {
  return (
    <Box display="flex" flexDirection="column" width={"100vw"} height={"100vh"}>
      <Box>
        <AppBar position="static" color="transparent" sx={{bgcolor: "white"}} elevation={0}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              ReadBest Admin
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Divider />
      <Box flex={1} overflow={'scroll'}>
        <Grid container spacing={2} height={"100%"}>
          <Grid item md={3} sm={4} xs={4} xl={2}>
            <Box display="flex" height="100%">
              <Box flex={1} height="100%" bgcolor="white" overflow={'scroll'}>
                <List>
                  <ListItem button>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText primary="Learn" />
                  </ListItem>
                </List>
              </Box>
              <Box>
                <Divider orientation="vertical" />
              </Box>
            </Box>
          </Grid>
          <Grid item  md={9} sm={8} xs={8} xl={10} height={'100%'} overflow={'scroll'}>
            <Box p={2} bgcolor={'rgba(243, 246, 249, 0.6)'} position={'relative'} display="block" height={'inherit'} width={'inherit'} overflow={'scroll'}>
              {children}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
