import React from 'react'
import MainLayout from '../../layouts/main/main'
import { Avatar, Box, Card, Checkbox, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material'

export default function Users() {
  return (
    <MainLayout>
        <Card>
            <Box mt={4} ml={2}>

            <TextField label="Search" />
            </Box>

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {[0, 1, 2, 3].map((value) => {
        const labelId = `checkbox-list-secondary-label-${value}`;
        return (
          <ListItem
            key={value}
            // secondaryAction={
            //   <Checkbox
            //     edge="end"
                
            //   />
            // }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${value + 1}`}
                  src={`/static/images/avatar/${value + 1}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
          </Card>
    </MainLayout>
  )
}
