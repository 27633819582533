import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { FirestoreProvider, useFirebaseApp, useInitPerformance } from "reactfire";
import FeedbackProvide from "./providers/feedback/feeedback";
import FirebaseAuthProvider from "./providers/firebase_auth/firebase_auth_provider";
import { UserProvider } from "./providers/user_provider/user_provider";
import routes from "./routes";


const queryClient = new QueryClient();

function App() {
  const app = useFirebaseApp();
  // const analytics = useAnalytics();
  const analytics = getAnalytics(app);

  const firestore = getFirestore(app);

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import("firebase/performance");
    return getPerformance(firebaseApp);
  });
  return (
    <FeedbackProvide>
          <FirebaseAuthProvider>
            <FirestoreProvider sdk={firestore}>
              <QueryClientProvider client={queryClient}>
                <UserProvider>
                  <RouterProvider router={routes} />
                </UserProvider>
              </QueryClientProvider>
            </FirestoreProvider>
          </FirebaseAuthProvider>
    </FeedbackProvide>
    
  );
}

export default App;
