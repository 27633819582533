import React from 'react'
import MainLayout from '../../layouts/main/main'

export default function dashboard() {
  return (
    <MainLayout>
      
    </MainLayout>
  )
}
